import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get(page, pageSize) {
    return client.get(`Roles/paginated?page=${page}&pageSize=${pageSize}`);
  },

  all() {
    return client.get(`Roles`);
  },

  save(user) {
    return client.post(`Roles`, user);
  },

  update(user) {
    return client.put(`Roles`, user);
  },

  delete(id) {
    return client.delete(`Roles?id=${id}`);
  },

  getOrganizationRoles(orgId) {
    return client.get(`readOrganizationManager?organizationId=${orgId}`);
  },
};
