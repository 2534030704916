import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get(page, pageSize) {
    return client.get(`Warehouses/paginated?page=${page}&pageSize=${pageSize}`);
  },

  save(data) {
    return client.post(`Warehouses`, data);
  },

  searchBin(query) {
    return client.get(`Warehouses/bins?binNumber=${query}`);
  },
};
