import Auth from "./modules/common/auth";

import Common from "./modules/common";

import Admin from "./modules/admin";
import Organization from "./modules/organization";
import Products from "./modules/products";
import Reports from "./modules/reports";

export default {
  Auth,
  Admin,
  Common,
  Products,
  Organization,
  Reports,
};
