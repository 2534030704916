import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  uploadLogo(data) {
    const formData = new FormData();
    formData.append("file", data, data.name);

    return client.post(`Organizations/uploadlogoimage`, formData, {
      headers: { "content-type": "multipart/form-data" },
    });
  },

  get() {},

  save(branding, data) {
    const formData = new FormData();
    const params = {
      primaryColor: branding.primaryColor,
      secondaryColor: branding.secondaryColor,
    };
    formData.append("file", data, data.name);

    return client.post(`Organizations/branding`, formData, {
      headers: { "content-type": "multipart/form-data" },
      params: params,
    });
  },
};
