import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get(page, pageSize) {
    return client.get(`Orders/paginated?page=${page}&pageSize=${pageSize}`);
  },

  save(data) {
    return client.post(`Orders`, data);
  },

  remove(id) {
    return client.delete(`Orders?id=${id}`);
  },
};
