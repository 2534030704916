import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get() {
    return client.get(`Organizations/connections`);
  },

  goTo() {
    return client.get(`Organizations/connection/link`);
  },

  stripeBalance() {
    return client.get(`Organizations/connections/stripe/account/balance`);
  },

  stripeTransactions() {
    return client.get(
      `Organizations/connections/stripe/account/balance/transactions`
    );
  },
};
