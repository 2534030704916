import Users from "./users";
import Roles from "./roles";
import Organisations from "./organisations";
import Permissions from "./permissions";
import Subscriptions from "./subscriptions";

export default {
  Users,
  Roles,
  Permissions,
  Organisations,
  Subscriptions,
};
