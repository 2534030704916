import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get(page, pageSize, search) {
    const searchQuery = search ? `&productName=${search}` : "";
    return client.get(
      `Products/paginated?page=${page}&pageSize=${pageSize}${searchQuery}`
    );
  },

  find(search) {
    return client.get(`Products/list?productName=${search}`);
  },

  save(data) {
    return client.post(`Products`, data);
  },

  saveFeature(data) {
    return client.post(`Products/feature`, data);
  },

  remove(id) {
    return client.delete(`Products?id=${id}`);
  },

  update(data) {
    data.price = parseFloat(data.price);
    return client.put(`Products`, data);
  },
};
