import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  getInvoices() {
    return client.get(`Subscriptions/invoices`);
  },

  getPlans() {
    return client.get(`Subscriptions/subscription-plans`);
  },

  getPaymentDetails() {
    return client.get(`Subscriptions/paymentmethods`);
  },

  updatePaymentDetails(id) {
    return client.post(`Subscriptions/paymentmethod?newPaymentMethodId=${id}`);
  },

  updatePlan(data) {
    return client.put(`Subscriptions/update-subscription`, data);
  },

  currentPlan() {
    return client.get(`Subscriptions/subscription-plan/current`);
  },

  deleteCard(id) {
    return client.delete(`Subscriptions/paymentmethod?cardId=${id}`);
  },

  customerPortal() {
    return client.get(`Subscriptions/customer-portal`);
  },
};
