import { ApiClient } from "@/services/client";

let client = new ApiClient();

const formatDueDate = (date) => {
  if (date) {
    return new Intl.DateTimeFormat("en-US").format(date);
  }
  return "";
};

export default {
  salesByDay: {
    get: async (startDate, endDate) => {
      return await client.get(
        `Invoices/paid/sales/report?startDate=${formatDueDate(
          startDate
        )}&endDate=${formatDueDate(endDate)}`
      );
    },
  },
};
