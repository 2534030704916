/*jshint esversion: 6 */
import axios from "axios";
// import Raven from "raven-js";
import store from "@/store";
import { ElMessage } from "element-plus";
import { ElNotification } from "element-plus";
import decode from "jwt-decode";
import { getRefreshToken } from "./getRefreshToken";

/*
use case:
    - Building a full url from a path
    - Assigning default headers
    - Transforming the request and response body (eg, convert between camelcase and snake case)
    - Normalizing and handling error responses
    - Global handling of network errors
    - Automatically setting the Authorization header on requests
    - Refreshing access tokens and retrying requests
    - Redirecting to a login page if there's an authentication error
    - Customer adapters
    - Caching
*/

const isTokenExpired = (token) => {
  if (token === null) return false;

  try {
    const decoded = decode(token);
    const validToken = decoded.exp < Date.now() / 1000;
    return validToken;
  } catch (error) {
    return true;
  }
};

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = () => {
  const options = {
    baseURL: process.env.VUE_APP_API_LOCATION,
  };

  if (
    store.getters["auth/getToken"] !== undefined &&
    store.getters["auth/getToken"] !== null
  ) {
    options.headers = {
      Authorization: `Bearer ${store.getters["auth/getToken"]}`,
    };
  }

  const client = axios.create(options);

  // Request interceptor for API calls
  client.interceptors.request.use(
    async (requestConfig) => {
      if (
        store.getters["auth/getToken"] !== undefined &&
        store.getters["auth/getToken"] !== null
      ) {
        requestConfig.headers.Authorization = `Bearer ${store.getters["auth/getToken"]}`;
      }

      if (
        isTokenExpired(store.getters["auth/getToken"]) &&
        store.getters["wizard/getToken"] === null
      ) {
        const response = await getRefreshToken(
          store.getters["auth/getRefreshToken"]
        );

        requestConfig.headers.Authorization = `Bearer ${response.data.access_token}`;

        store.dispatch("auth/setToken", response.data.access_token);
        store.dispatch("auth/setRefreshToken", response.data.refresh_token);

        ElMessage({
          message: "Re Authenticating. Please wait...",
          "custom-class": "re-authenticating",
        });

        return requestConfig;
      }

      return requestConfig;
    },
    (requestError) => {
      //Raven.captureException(requestError);

      return Promise.reject(requestError);
    }
  );

  // Response interceptor for API calls
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (responseError) => {
      //Raven.captureException(responseError);
      if (
        responseError.response !== undefined &&
        responseError.response.data !== undefined
      ) {
        if (responseError.response.data.error !== undefined) {
          ElMessage.error("Problem with the request. Please try again.");
          return;
        }
        responseError?.response?.data?.AdditionalMessages?.forEach(
          (message) => {
            ElNotification({
              title:
                responseError.response.status >= 400 &&
                responseError.response.status < 500
                  ? "Warning"
                  : "Error",
              message: message,
              type:
                responseError.response.status >= 400 &&
                responseError.response.status < 500
                  ? "warning"
                  : "error",
              duration: 0,
            });
          }
        );
      }

      return responseError;
    }
  );

  return client;
};

class ApiClient {
  constructor(baseUrl = null) {
    this.client = getClient(baseUrl);
  }

  get(url, conf = {}) {
    return this.client
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  delete(url, conf = {}) {
    return this.client
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  head(url, conf = {}) {
    return this.client
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  options(url, conf = {}) {
    return this.client
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  post(url, data = {}, conf = {}) {
    return this.client
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  put(url, data = {}, conf = {}) {
    return this.client
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  patch(url, data = {}, conf = {}) {
    return this.client
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }
}

export { ApiClient };

/**
 * Base HTTP Client
 */
export default {
  // Provide request methods with the default base_url
  get(url, conf = {}) {
    return getClient()
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  delete(url, conf = {}) {
    return getClient()
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  head(url, conf = {}) {
    return getClient()
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  options(url, conf = {}) {
    return getClient()
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  post(url, data = {}, conf = {}) {
    return getClient()
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  put(url, data = {}, conf = {}) {
    return getClient()
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  patch(url, data = {}, conf = {}) {
    return getClient()
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
