import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get(page, pageSize) {
    return client.get(`Invoices?page=${page}&pageSize=${pageSize}`);
  },

  getDraft(page, pageSize, search) {
    const searchCustomer =
      search?.length > 0 ? `&searchCustomer=${search}` : "";
    return client.get(
      `Invoices/paginated/draft?page=${page}&pageSize=${pageSize}${searchCustomer}`
    );
  },

  getOverdue(page, pageSize) {
    return client.get(
      `Invoices/paginated/overdue?page=${page}&pageSize=${pageSize}`
    );
  },

  getPaid(page, pageSize) {
    return client.get(
      `Invoices/paginated/paid?page=${page}&pageSize=${pageSize}`
    );
  },

  getSent(page, pageSize, search, dueDate) {
    const formatDueDate = () => {
      if (dueDate) {
        return new Intl.DateTimeFormat("en-US").format(dueDate);
      }
      return "";
    };

    const searchCustomer =
      search?.length > 0 ? `&searchCustomer=${search}` : "";
    const dueDateQuery = dueDate ? `&dueDate=${formatDueDate()}` : "";
    return client.get(
      `Invoices/paginated/sent?page=${page}&pageSize=${pageSize}${searchCustomer}${dueDateQuery}`
    );
  },

  saveAsDraft(data) {
    return client.post(`Invoices/draft`, data);
  },

  send(data) {
    return client.post(`Invoices/send`, data);
  },

  getUnpaid(page, pageSize) {
    return client.get(
      `Invoices/paginated/unpaid?page=${page}&pageSize=${pageSize}`
    );
  },

  update(data) {
    return client.put(`Invoices`, data);
  },

  void(data) {
    return client.put(`Invoices/void`, data);
  },

  uncollectible(data) {
    return client.put(`Invoices/uncollectible`, data);
  },
};
