import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get() {
    return client.get(`Notifications/list`);
  },

  getByType(type) {
    return client.get(`Notifications/type?type=${type}`);
  },

  filter(type, status) {
    return client.get(`Notifications/filter?${type}=4&status=${status}`);
  },

  count() {
    return client.get(`Notifications/count`);
  },

  save(data) {
    return client.post(`Notifications`, data);
  },
};
