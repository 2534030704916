import axios from "axios";
import qs from "qs";

axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;

const getRefreshToken = async (refreshToken) => {
  const tokenUrl =
    process.env.VUE_APP_API_LOCATION.replace("api/", "") + `connect/token`;

  const headers = {
    accept: "*/*",
    "content-type": "application/x-www-form-urlencoded",
  };

  const data = {
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    grant_type: "refresh_token",
    refresh_token: refreshToken,
    scope: "offline_access",
  };

  return await axios.post(tokenUrl, qs.stringify(data), headers);
};

export { getRefreshToken };
