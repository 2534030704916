import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get(page, pageSize, search) {
    return client.get(
      `Customers?page=${page}&pageSize=${pageSize}&name=${search}`
    );
  },

  getAll() {
    return client.get(`Customers/list`);
  },

  save(data) {
    return client.post(`Customers`, data);
  },

  update(data) {
    return client.put(`Customers`, data);
  },

  delete(id) {
    return client.delete(`Customers?id=${id}`);
  },
};
