import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get(page, pageSize) {
    return client.get(
      `SystemUsers/paginated?page=${page}&pageSize=${pageSize}`
    );
  },

  save(user) {
    return client.post(`SystemUsers`, user);
  },

  update(user) {
    return client.put(`SystemUsers`, user);
  },

  getBy(item) {
    return client.get(`SystemUsers/${item}`);
  },

  search(name) {
    return client.get(`SystemUsers/search?userName=${name}`);
  },

  delete(id) {
    return client.delete(`SystemUsers?SystemUserId=${id}`);
  },

  uploadImage(data) {
    const formData = new FormData();
    formData.append("file", data, data.name);

    return client.post(`SystemUsers/uploaduserimage`, formData, {
      headers: { "content-type": "multipart/form-data" },
    });
  },

  removeFromOrg(orgId) {
    return client.delete(`Users?OrganizationUserId=${orgId}`);
  },

  organizationInvite(username, orgId) {
    return client.post(
      `Users/OrganizationInvite?Username=${username}&OrganizationId=${orgId}`
    );
  },
};
