import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get(page, pageSize) {
    return client.get(
      `Organizations/paginated?page=${page}&pageSize=${pageSize}`
    );
  },

  search(search, page, pageSize) {
    return client.get(
      `Organizations/paginatedSearch?partialName=${search}&page=${page}&pageSize=${pageSize}`
    );
  },

  save(data) {
    return client.post(`/Organizations`, data);
  },

  delete(id) {
    return client.delete(`Organizations?id=${id}`);
  },

  setAdmin(data) {
    return client.put(`/Organizations/assignAdmin`, data);
  },

  removeAdmin(data) {
    return client.put(`/Organizations/removeAdmin`, data);
  },

  getUsers(orgId) {
    return client.get(`Organizations/Users?OrganizationId=${orgId}`);
  },
};
