import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get(page, pageSize) {
    return client.get(`Users/paginated?page=${page}&pageSize=${pageSize}`);
  },

  save(user) {
    return client.post(`Users`, user);
  },

  update(user) {
    return client.put(`Users`, user);
  },

  getBy(item) {
    return client.get(`Users/${item}`);
  },

  search(name) {
    return client.get(`Users/search?userName=${name}`);
  },

  invite(username, orgId, roleId) {
    return client.post(
      `Users/OrganizationInvite?Username=${username}&OrganizationId=${orgId}&RoleId=${roleId}`
    );
  },

  invites() {
    return client.get(`Users/OrganizationInvites`);
  },

  removeInvite(id) {
    return client.delete(`Users/OrganizationInvite?InviteId=${id}`);
  },
};
