import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get(page, pageSize) {
    return client.get(
      `Subscriptions/paginated?page=${page}&pageSize=${pageSize}`
    );
  },
};
