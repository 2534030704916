import { ApiClient } from "@/services/client";

let client = new ApiClient();

export default {
  get() {
    return client.get(`Invoices/taxrates`);
  },

  save(data) {
    return client.post(`Invoices/taxrate`, data);
  },
};
