import Users from "./users";
import Roles from "./roles";
import Settings from "./settings";
import Subscriptions from "./subscriptions";
import Notifications from "./notifications";
import Customers from "./customers";
import Invoices from "./invoices";
import Connections from "./connections";
import TaxRates from "./taxRates";

export default {
  Users,
  Roles,
  Settings,
  Customers,
  Invoices,
  Subscriptions,
  Notifications,
  Connections,
  TaxRates,
};
